import { render, staticRenderFns } from "./categoryID.vue?vue&type=template&id=c9b31c2a&"
import script from "./categoryID.vue?vue&type=script&lang=js&"
export * from "./categoryID.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VListTileAvatar } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VRadio } from 'vuetify/lib'
import { VRadioGroup } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VSubheader } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTextarea } from 'vuetify/lib'
installComponents(component, {VAutocomplete,VBtn,VCard,VCardActions,VCardText,VContainer,VDivider,VFlex,VImg,VLayout,VListTileAvatar,VListTileContent,VListTileTitle,VRadio,VRadioGroup,VSpacer,VSubheader,VTextField,VTextarea})
