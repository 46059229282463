<template>
  <v-layout class="mb-4 pb-4" wrap align-start justify-start row>
    <v-flex xs12>
      <Title title="Nueva Categoria" subtitle=""></Title>
    </v-flex>
    <v-flex xs12>
      <v-card>
        <v-card-text>
          <v-container grid-list-lg fluid>
            <v-layout row wrap>
              <v-flex xs12 sm12 md12>
                <v-text-field v-model="name" label="Titulo" hide-details></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-text-field v-model="url" label="URL (debe ser unico)" hide-details></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md12>
                <v-subheader> Seo </v-subheader>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-text-field v-model="seoTitle" label="Title" hide-details></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-textarea v-model="seoDescription" label="Description"></v-textarea>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-textarea v-model="seoKeywords" label="Keywords"></v-textarea>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-radio-group v-model="active" class="pa-0 ma-0" :mandatory="false">
                  <v-radio color="secondary" label="Inactivo" :value="false"></v-radio>
                  <v-radio color="secondary" label="Activo" :value="true"></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex v-if="id > 0" xs12 sm6 md6>
                <v-autocomplete
                  v-model="product"
                  :items="productsList"
                  :item-text="
                    item => {
                      return `${item.name} - P${item.id}`
                    }
                  "
                  flat
                  cache-items
                  hide-no-data
                  persistent-hint
                  label="Producto destacado"
                  hint="Solo se mostrara en las categorias de la home"
                  return-object
                >
                  <template slot="item" slot-scope="{ item }">
                    <v-list-tile-avatar>
                      <v-img :src="item.img.url" aspect-ratio="1" class="grey lighten-2"> </v-img>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                    </v-list-tile-content>
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" :loading="loading" :disabled="loading" flat @click="save"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { CONFIG, GET_SEARCH_PRODUCTS } from '../../config'
import Title from '../useful/title.vue'

export default {
  name: 'CMSId',
  components: {
    Title
  },
  props: ['id'],
  data() {
    return {
      url: '',
      name: '',
      description: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      active: false,
      loading: false,
      product: null,
      productsList: []
    }
  },
  mounted() {
    if (this.id !== 'new') {
      this.$http(`${CONFIG}/categories/${this.id}`).then(res => {
        const item = res.data
        this.url = item[0].url
        this.name = item[0].name
        this.description = item[0].description
        this.seoTitle = item[0].seoTitle
        this.seoDescription = item[0].seoDescription
        this.seoKeywords = item[0].seoKeywords
        this.active = item[0].active
        this.loadProducts(item[0].id, item[0].product_id)
      })
    }
  },
  methods: {
    async save() {
      this.loading = true
      try {
        const res = await this.$http({
          method: this.id ? 'put' : 'post',
          url: this.id ? `${CONFIG}/categories/${this.id}` : `${CONFIG}/categories`,
          data: {
            item: {
              name: this.name,
              url: this.url,
              description: this.description,
              seoTitle: this.seoTitle,
              seoDescription: this.seoDescription,
              seoKeywords: this.seoKeywords,
              active: this.active,
              product: this.product ? this.product.id : null
            }
          }
        })
        this.loading = false
        if (this.id === 'new') {
          this.$router.replace({
            name: 'settingsCategoriesEdit',
            params: { id: res.data }
          })
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async loadProducts(id, product_id) {
      try {
        this.addProduct = true
        const res = await this.$http.get(GET_SEARCH_PRODUCTS, {
          params: {
            dateShipping: new Date().toISOString().substr(0, 10),
            stateId: 1,
            schedule: 0,
            actives: 1
          }
        })
        const products = res.data.filter(item => {
          return item.categories.find(item => item.id === id)
        })
        this.productsList = products
        this.product = products.find(item => item.id === product_id)
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    }
  }
}
</script>
